.category_listing_sec2 { width: 100%; padding-top: 30px;}
		.category_listing_sec2 h3{font-size: 28px; padding-bottom: 10px;}
		.category_listing_sec2 h3 span{ display: block;font-size: 10px;color: #828282; padding-bottom:2px;letter-spacing: 1.5px;font-family: Open Sans; text-transform: uppercase;}
		.category_listing_sec2 abbr{border-radius: 5px; display: block; overflow: hidden;}
		.category_listing_sec2 abbr div{ display:block !important;}
		.category_listing_sec2 p{font-size: 13px;letter-spacing: 0.5px; line-height: 1.9;}
		.listing_sec2_row ul li a { letter-spacing: 1.25px;color: #ffffff;  font-size: 12px; text-transform: uppercase;  background:#C4601D; border-radius: 5px;display: table;  padding: 16px 10px;text-align: center; min-width: 255px; font-weight: 600;border: 1px solid #C4601D;}
		.category_listing_sec2 ul{ display: block; padding-top:5px; width: 100%;}
		.listing_sec2_row {  border-radius: 5px; box-shadow: 0px 0px 6px rgb(49 49 49 / 16%); background: #fff;padding: 25px; display: inline-block;margin-left: -100px; width: 500px;    position: relative;}
		.category_listing_sec2 .row { margin-bottom: 50px;}
		.category_listing_sec2 .row:last-child { margin-bottom: 0px;}
		.category_listing_sec2 .row:nth-child(even) .listing_sec2_row{ margin-right: -90px; margin-left: 0;    position: relative;z-index: 1;}
		.category_listing_sec2 .row:nth-child(even) .listing_sec2_row li a { min-width: 225px;}
		.category_listing_sec2 .row:nth-child(even) .listing_sec2_row li.float-start a{border-radius: 5px; border: 1px solid #C4601D; 
			background:#ffffff;color: #C4601D;}
			.category_listing_sec2 abbr img{ display: block;}
			.category_listing_sec2_carousel{ position: relative;}


			@media (max-width: 767px){
				.category_listing_sec2 abbr img{ width: 100%; height: auto;}	
				.listing_sec2_row {     margin-top: 0px;   margin-left: 0;padding:8px;	
					width: 100%;}
					.category_listing_sec2 h3{ font-size: 18px;font-family: Cormorant Garamond;
line-height: 24px;font-weight: 500;padding-bottom: 10px; min-height: 115px;} 
					.category_listing_sec2 h3 span{ padding-bottom: 5px;}
					.listing_sec2_row ul li a { width: 100%;    padding: 12px 10px; min-width: 100%;}
					.category_listing_sec2 abbr{ margin-bottom: 10px; border-bottom-left-radius: 5px; border-bottom-right-radius:5px;}
					.category_listing_sec2_carousel{ margin: 0 -7px;}
					.category_listing_sec2{ padding-right: 7px;    
						padding-left: 7px;margin-bottom: 15px;}
						.category_listing_sec2 ul li{ width: 100%;}
			}