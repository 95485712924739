@import '/styles/_global';
.teaserBannerParent{position: relative; display: flex;justify-content: center; align-items: center; margin-top: 2rem;}
.teaserBanner { width: 100%; height: auto; display: block; }
.teaserBannerText {padding: 30px 0px; position: absolute; width: 100%; top: 50%;left: 50%; transform: translate(-50%, -50%); }
.teaserBannerHead{font-family: 'DIN Condensed'; font-weight: 600; font-size: clamp(3.5rem, 4vw ,5rem); color: #fff; text-align: center; margin-bottom: 0px !important;}
.teaserBannerDate{font-family: 'DIN Light'; font-weight: 400; font-size: clamp(2.4rem, 2.8vw ,3.375rem); color: #fff; text-align: center;margin-bottom: 0px !important;}
   
@media (max-width: 992px){
    .teaserBannerHead{font-size: clamp(1.5rem, 6.45vw, 4rem); text-align: center;}
    .teaserBannerDate{font-size: clamp(0.85rem, 2vw, 2.375rem); text-align: center;}
}