@use "sass:map";
$primary-color: #64ff00;
$baseFontSize: 16;

$designScreens: (
  "lg": 2560, 
  // "md": 992,
  "sm": 992,
);

$mobile-design-width: map-get(
  $map: $designScreens,
  $key: "sm",
);
// $tablet-design-width: map-get(
//   $map: $designScreens,
//   $key: "md",
// );
$desktop-design-width: map-get(
  $map: $designScreens,
  $key: "lg",
);

$screens: (
  "lg": 992,
  // "md": 700,
  "sm": 360, 
);
$mobile-width: map-get(
  $map: $screens,
  $key: "sm",
);
// $tablet-width: map-get(
//   $map: $screens,
//   $key: "md",
// );
$desktop-width: map-get(
  $map: $screens,
  $key: "lg",
);

@function toRemDesktop($value: 16) {
  $remValue: (calc($value / 40.96)) + rem;
  @return $remValue;
}

@function toRem($value: 16) {
  $remValue: (calc($value / 44)) + rem;
  @return $remValue;
}

@mixin desktop {
  @media (min-width: #{$desktop-width}px) {
    @content;
  }
}
