@import '/styles/_global';
.productsCollection{
    display: flex;
    flex-wrap: wrap;
}

.productCollection{
    flex: 1 1 50%;
    position: relative;
}

@media screen and (max-width: 992px) {
    .productsCollection{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
   
}