@import '/styles/_global';
.productsCollection{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 4rem;
}

.productCollection{
    // flex: 1 1 50%;
    position: relative;
    flex-basis: 50%;
}

.test{
    color: #505252;
    font-family: "Bell MT";
    font-size: 21px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
}

.test2{
    color: #505252;
    font-family: "DIN Light";
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px; 
}

.productTextSection{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.productTextSection h3{
    color: #505252;
    text-align: center;
    font-family: 'DIN Bold';
    font-size: 43px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 93.023% */
    text-transform: uppercase;
    margin-top: 20px;
}

.productCollection .headingIcon {
    margin-top: 12px;
    margin-bottom: 13px;
}

.productTextSection p{
    color: #505252;
    text-align: center;
    font-family: "Bell MT";
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 39px; /* 139.286% */
}

.buyNowKumkumadi{
    width: 100%;
    max-width: 30%;
    color: #FFF;
    background: #b8202e;
    margin-top: 27px !important;
    border-radius: 5px;
    padding: 3px;
    cursor: pointer;
    display: block;
    margin: auto;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 11px;
    border: none;
    text-align: center;
    letter-spacing: 1.25px;
    line-height: 24px;
    color: #ffffff;
}

.buyNowKumkumadi img{
    margin-right: 5px;
    vertical-align: text-bottom;
}

@media screen and (max-width: 992px) {
    .productsCollection{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 1.5rem;
    }

    .productTextSection{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    
    .productTextSection h3{
        margin-top: 20px;
        color: #505252;
        text-align: center;
        font-family: 'DIN Bold';
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px; /* 200% */
        text-transform: uppercase;
    }
    
    .productCollection .headingIcon {
        margin-top: 2px;
        margin-bottom: 4px;
    }
    
    .productTextSection p{
        color: #505252;
        text-align: center;
        font-family: "Bell MT";
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px; /* 253.846% */
    }

    .buyNowKumkumadi{
        max-width: 55%;
        margin-bottom: 0.5rem !important;
        display: block;
        width: 100%;
        margin: auto;
    }
   
}