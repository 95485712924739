@import '/styles/_global';
.banner {
    min-height: toRem(545);
    height: 100%;
}

.mobile {
    display: block;
}

.desktop {
    display: none;
}

.categorycontainer {
    min-height: toRem(350);
    height: 100%;
}

.productCarousel {
    min-height: toRem(573);
    height: 100%;
}

@include desktop() {

    .mobile {
        display: none;
    }

    .desktop {
        display: block;
    }

    .banner {
        min-height: toRemDesktop(548);
    }

    .categorycontainer {
        min-height: toRemDesktop(424);
    }

    .productCarousel {
        min-height: toRemDesktop(611);
    }

}