@import '/styles/_global';
.productHydrationHeading{
    display: flex;
    justify-content: center;
    align-items: center;
}

.amCollection{
    display: flex;
}

// .imgAMCollection{
//     padding-bottom: 2.5rem;
// }

.pmCollection{
    display: flex;
}

// .imgPMCollection{
//     padding-bottom: 2.5rem;
// }

.productAM1{
    aspect-ratio: 4 / 3;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.productAM2{
    aspect-ratio: 15 / 11;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.productPM1{
    aspect-ratio: 13 / 9;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.productPM2{
    aspect-ratio: 13 / 9;
    width: 100%;
    height: auto;
    object-fit: cover;
}

@media screen and (max-width: 992px) {
    .amCollection{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    // .imgAMCollection{
    //     padding-bottom: 2rem;
    // }
    
    .pmCollection{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    // .imgPMCollection{
    //     padding-bottom: 2rem;
    // }

    .productAM1{
        aspect-ratio: 13 / 9;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .productAM2{
        aspect-ratio: 12 / 7;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .productPM1{
        aspect-ratio: 7 / 5;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .productPM2{
        aspect-ratio: 16 / 9;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}
