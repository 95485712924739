.new{
    font-weight: 600;
    letter-spacing: 1px;
    background: #c4601d;
    color: #fff;
    padding: 2px 8px;
    border-radius: 3px;
    font-size: 8px;
    line-height: 1;
    position: relative;
    top: -12px;
    font-family: 'Open Sans';
    text-transform: uppercase;
    white-space: nowrap;
}



