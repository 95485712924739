@import '/styles/_global';

.kumkumadiLowerBanner{
    aspect-ratio: 17 / 4;
    width: 100%;
    height: auto;
    object-fit: cover;
}

.kumkumadiLowerBannerParent{
    display: flex;
    position: relative;
}

@media screen and (max-width: 992px) {
    .kumkumadiLowerBanner{
        aspect-ratio: 22 / 5;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}