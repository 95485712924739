@import '/styles/_global';
.textParent{
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    font-family: 'DIN Condensed';
    font-size: clamp(10px, 2.4vw, 24px);
}

.row {flex-wrap: nowrap;  white-space: nowrap; overflow: auto; padding-top: 40px;
    padding-bottom: 15px; -webkit-overflow-scrolling: touch; overflow-scrolling: touch;-ms-overflow-style: none;  scrollbar-width: none;
      -webkit-transform: translateZ(0); transform: translateZ(0);scroll-behavior: smooth; margin: 0 -7px;}

.col-9{
    padding: 0 7px;
}

.teaserProductParent{
    padding-bottom: 3rem;
}

.carouselHeader{
  padding-top: 3rem;
}

@media (max-width: 992px){
  .textParent{
    font-size: clamp(10px, 2.4vw, 24px);
  }
}