@media screen and (min-width : 992px) {
    .container {
        margin: 6.25rem 0;

    }
}

.container {
    display: -webkit-flex;
    display: -moz-box;
    display: flex;
    -webkit-align-items: center;
    -moz-box-align: center;
    align-items: center;
    justify-self: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    justify-content: center;
    flex-direction: column;
    margin: 4.12rem 0;
}